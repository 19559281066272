export default {
  primary: '#1F9799',
  white: '#FFF',
  black: '#000',
  grey: 'rgb(246,246,246)',
  secondaryGrey: 'rgb(137,137,137)',
  placeholder: 'rgb(214,214,214)',
  subtitle: 'rgb(33,33,33)',
  subtitle2: 'rgb(64,64,64)',
  greyOutTitle: 'rgb(100,100,100)',
  shadow: 'rgba(21,114,115,0.5)',
  gradient1: '#6cd3ac',
  gradient2: '#41b3a2',
  inputLabel: 'rgb(124,124,124)',
  error: 'red',
  border: 'rgb(215,215,215)',
  divider: 'rgb(231,231,231)',
  alert: 'rgb(215,41,41)',
  warmGrey: '#8c8c8c',
  orange: '#eb5e00',
  orangeBorder: '#f0753e',
  secondaryText: '#212121',
  orangeMedium: '#ff7847'
} as const;
