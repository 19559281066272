import { createGlobalStyle, css, keyframes } from 'styled-components';
import Colors from '../../Colors';

const isMac = typeof window !== `undefined` && window?.navigator.userAgent.includes('Mac');
export const isSafariBrowser = typeof window !== `undefined` && window?.navigator.userAgent.indexOf('Safari') > -1 && window?.navigator.userAgent.indexOf('Chrome') <= -1;

export const HideNotificationAnim = keyframes`
  to {
    visibility: hidden;
    height: 0;
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Manrope',-apple-system,'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
    
  }

  html {
    overflow: hidden;
  }

  body {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .watermark {
    display: none;
  }

  button{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    color: var(--text);
    font: inherit;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    visibility: none;
    display: none;
  }

  .notification {
    animation: ${HideNotificationAnim} 3s ease-in 1s forwards;
  }
  .notification__item--success {
    background-color: ${Colors.primary};
    border-left: 8px solid darken(${Colors.primary}, 15%);
  }
  .notification__item--success .notification__timer {
    background-color: ${Colors.grey};
  }

  ${
    !isMac &&
    css`
      *::-webkit-scrollbar {
        background-color: none;
        width: 6px;
        height: 6px;
      }
      *::-webkit-scrollbar-track {
        background-color: none;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
      }
      *::-webkit-scrollbar-button {
        display: none;
      }

      @media (max-width: 768px) {
        *::-webkit-scrollbar {
          width: 3px;
        }
      }
    `
  }
  p {
    margin: 0;
    padding: 0;
  }
  input:disabled {
    border: none !important;
    background: #f4f4f4;
  }
  [class^='notification__item'], [class*=' notification__item'] {
    border: none;
  }
  .notification__timer-filler {
    opacity: 0;
  }
  .animate__fadeIn {
    opacity: 1;
    transition: opacity .5s ease;
  }
  .animate__fadeOut {
    z-index: 0;
    opacity: 0;
    transition: opacity .5s ease;
  }

/* 
  .notification :has(.notification__action) {
    transition:none !important;
    width: auto !important;
    height: auto !important;
  }

  .notification__action.notification__item--info {
    width: 459px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    z-index: 100;
    border-left:none;
    color: ${Colors.white};
    padding: 8px;
    font-weight: 800;
    text-align: center;
    background-color: ${Colors.orangeMedium};
    box-shadow: 0 0 30px 0 rgba(209, 56, 0, 0.33);
    animation:none;
    .notification__content{
      padding:0;
      background-color: ${Colors.orangeMedium};
      z-index: 0;

      .notification__message {
        font-size: 16px;
        font-family: 'Manrope';
      }

      .notification__close {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border: none;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        margin-right: 4px;
        outline: none;
        cursor: pointer;
        margin-left: auto;
        background-color: ${Colors.orangeMedium};
        background-image: url(${'../../images/closeTransparent.png'});
        :after{
          display:none;
        }
      }
    }
  }

  .notification__action.notification__item--info.animate__slideInDown{
    @keyframes slideInDown {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
    animation: slideInDown 0.3s ease-in;

  }

  .notification__action.notification__item--info.animate__slideOutUp{
    @keyframes slideOutUp {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-100%);
      }
    }
    animation: slideOutUp 0.3s ease-out;
}

 */


  .wrapper-class {
    border: 1px solid black;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .editor-class {
    padding: 1rem;
    height: 400px;
    overflow: scroll;
  }
  .toolbar-class {
  }
  .rdw-option-wrapper {
    border: none;
    padding: 1px;
    min-width: 25px;
    height: 20px;
    border-radius: 4px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    &:hover{
      box-shadow: none;
      background: #e7e7e7;
    }
  }
`;
