import React from 'react';

import { GlobalStyle } from './src/components/Shared/Shared';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  );
};
